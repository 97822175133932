import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import UpNext from "../components/UpNext";
import SectorGrid from "../components/SectorGrid";
import Map from "../../static/img/partner-map.png";
import SEO from "../components/SEO";

// eslint-disable-next-line
export const PartnerPage = (props) => {

    const frontmatter = props.data.markdownRemark.frontmatter;
    const title = frontmatter.title,
          body = frontmatter.content,
          valueprops = frontmatter.valueprops,
          sectors = frontmatter.partnersectors,
          contentComponent = HTMLContent,
          PageContent = contentComponent || Content;
    const upNext = props.data.allMarkdownRemark.nodes[0].frontmatter.upnextlinks.upnextpartners;
    return (
    <React.Fragment>
        <SEO {...props.data.seo} />
        <Helmet bodyAttributes={{ class: "page-partners on-dark" }}/>
        <section className="section">
            <div className="radial-background"></div>
            <div className="container">
                <div className="page-title">
                    <h6 className="has-text-centered">Our Network</h6>
                    <h1 className="title has-text-centered">{title}</h1>
                </div>
                <div className="data-container is-flex is-justify-content-center is-align-items-center">
                    <div className="data-container-inner columns">
                        <div className="column is-one-third">
                            <h2 className="has-text-centered">37</h2>
                            <p className="has-text-centered">countries our investors are based in</p>
                        </div>
                        <div className="column is-one-third">
                            <h2 className="has-text-centered">240</h2>
                            <p className="has-text-centered">industry-leading investors in our funds</p>
                        </div>
                        <div className="column is-one-third">
                            <h2 className="has-text-centered">1.2M</h2>
                            <p className="has-text-centered">people employed by our investors' brands</p>
                        </div>
                    </div>
                </div>
                <div className="content-container columns">
                  <PageContent className="content" content={body} />
                  <div className="partners-locations">
                    <img src={Map} alt="Map of locations of HOF's partners" className="partners-map"/>
                    <h5 className="partners-locations-label">Strategic partners across:</h5>
                    <ul className="partners-locations-list">
                      <li className="partners-locations-item">North America</li>
                      <li className="partners-locations-item">South America</li>
                      <li className="partners-locations-item">Europe</li>
                      <li className="partners-locations-item">Africa</li>
                      <li className="partners-locations-item">Asia</li>
                    </ul>
                  </div>
                </div>
                <h4 className="partners-heading">Partner Industries</h4>
                <div className="partners-sectors">
                  <SectorGrid sectors={sectors}></SectorGrid>
                </div>
                <h4 className="partners-heading">Creating value through</h4>
                <ul className="partners-value-props">
                  {valueprops.map(function(item){
                    return (
                      <li className="partners-value-prop">
                        <h4 className="partners-value-prop-heading">{item.valueprop}</h4>
                        <PageContent className="partners-value-prop-description" content={item.description} />
                      </li>
                    )
                  })}
                </ul>
                <UpNext url={upNext.linkurl} text={upNext.linktext}/>
            </div>
        </section>
    </React.Fragment>
    );
};

PartnerPage.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  upnextlinks: PropTypes.object
}

export default PartnerPage;

export const partnerPageQuery = graphql`
  query PartnerPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        content
        valueprops {
          valueprop
          description
        }
        partnersectors {
          sectorname
        }
      }
    }
    seo: markdownRemark(id: {eq: $id}) {
      ...SEOFragment
    }
    allMarkdownRemark(filter: {frontmatter: {filterKey: {eq: "globalsettings"}}}) {
    nodes {
      frontmatter {
        upnextlinks {
          upnextpartners {
            linktext
            linkurl
          }
        }
      }
    }
  }
  }
`;