import React from 'react'

const SectorGrid = (props) => {
  console.log(props);
    return (
        <section className="sector-grid">
            {props.sectors.map(function(sector, i){
                return (
                    <div className="sector">
                        <h5>{sector.sectorname}</h5>
                    </div>
                )
            })}
        </section>
    )
}

SectorGrid.propTypes = {
}


export default SectorGrid;
